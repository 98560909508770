import React from "react";

import tutorial from "../data/tutorial";
import {Link} from "gatsby";

const NavLink = ({to, children}) => (
    <Link className="px-0 py-1 nav-item nav-link font-weight-light" to={to}>{children}</Link>);
const RegularLink = ({to, children}) => (
    <a className="px-0 py-1 nav-item nav-link font-weight-light" href={to}>{children}</a>);

const Footer = ({className = ''}) => (
    <footer className={`${className} container-fluid`}>
        <div className="row">
            <div className="col-6 col-md-4">
                <h6 className="text-uppercase text-info">Tutorial</h6>
                <nav className="nav flex-column">
                    {tutorial.map(({to, name}) => (
                        <NavLink key={name} to={to}>{name}</NavLink>)
                    )}
                </nav>
            </div>

            <div className="col-6 col-md-4">
                <h6 className="text-uppercase text-info">Docs</h6>
                <nav className="nav flex-column">
                    <NavLink to="/ApiDoc/">Overview</NavLink>
                    <NavLink to="/ApiDoc/build-app/">Compiling & Linking</NavLink>
                    <NavLink to="/ApiDoc/build-lib/">Building SQLAPI++</NavLink>
                    <RegularLink to="/ApiDoc/annotated.html">Class Library</RegularLink>
                    <NavLink to="/ApiDoc/servers/">Server Specific Guides</NavLink>
                </nav>

                <h6 className="text-uppercase text-info mt-5">Support</h6>
                <nav className="nav flex-column">
                    <NavLink to="/Support/">Technical Support</NavLink>
                    <NavLink to="/Support/faq/">FAQ</NavLink>
                    <NavLink to="/Support/privacy/">Privacy Policy</NavLink>
                    <RegularLink to="https://www.freelists.org/list/sqlapi-announce">Mailing List</RegularLink>
                    <NavLink to="/Download/history/">Release Notes</NavLink>
                </nav>
            </div>

            <div className="col-12 col-md-4 order-md-first mt-5 mt-md-0 text-center text-md-left text-muted">
                <div>© SQLAPI++ {new Date().getFullYear()}</div>
                <div><a href="mailto:webmaster@sqlapi.com">webmaster@sqlapi.com</a></div>
            </div>
        </div>
    </footer>
);

export default Footer;
